/**
 * Order is very important.
 * 1st: SCSS variable overrides
 * 2nd: build import
 * 3rd: component hooks
 * 4th: component import
 * 5th: CSS variables declaration/override (build includes :root level declaration)
 * 6th: other custom CSS
 */

// font
//@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@200;300;400;500;600;700;800&display=swap');
//@import url('https://fonts.googleapis.com/css2?family=Varela%20Round:wght@200;300;400;500;600;700;800&display=swap');

// alternative font: alef
//@import url('https://fonts.googleapis.com/css2?family=Alef:wght@200;300;400;500;600;700;800&display=swap');

//@import url('https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@100;300;400;500;700;800;900&display=swap');

//@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@100;200;300;400;500;600;700;800;900&display=swap');
// 1st: SCSS variable overrides
$global-prefix: "";

// 2nd: build import
@import "../../../../../../otherSrc/client/we/scss/build";

// our custom component // * NOTE: we can not include partial SCSS files inside the client forlder, atm.

// 3rd: component hooks

@mixin hook-button {
  &--facebook {
    @include _button-color(
                    var(--c-facebook),
                    var(--c-bg),
                    var(--c-bg),
                    var(--c-facebook),
                    true
    );
    border: 2px solid var(--c-facebook);
  }


}
@mixin hook-form-field-element {
  padding: calc(var(--margin-s) * 1.4) 2em;

  [dir=ltr] & { //ltr font causes thin fields in different size to button. fix this
    padding: calc(var(--margin-s) * 1.8) 2em;
  }
}
@mixin hook-modal {
  &__header {
    min-height: 2.5em;
    // border: 3px solid red;
  }
}

// select 2 placholder
.select-2-base-input::placeholder,
.field__controls-wrapper--select2 .pseudo-placeholder {
  color: var(--c-text)!important;
  opacity:1!important;
}

// select, select 2 dropdown icon color
.field.field--theme-default .field__controls-wrapper--select2 .field__icon--end,
.field.field--theme-default .field__controls-wrapper--select .field__icon--end{
  background-color: var(--c-alt) !important;
}

// 4th: component import
@import "../../../../../../otherSrc/client/we/scss/components";

// 5th: CSS variables declaration/override
:root, html body {
  --c-danger                  : #bc073a;
  //  --c-lead-4                  : #276e93;
  //--c-core-lead-4             : #276e93;

  --c-core-lead-1                  : #5AA1C6;

  --c-core-lead-2                  : #1a99ce;
  --c-core-lead                    : #276e93;
  --c-core-lead-4                  : #15628a;
  --c-core-lead-5                  : #002247;

  --c-lead-1                  : #5AA1C6;
  --c-lead-2                  : #1a99ce;
  --c-lead                    : #276e93;
  --c-lead-4                  : #15628a;
  --c-lead-5                  : #002247;

  --c-core-alt-1                  : #FF5487;
  --c-core-alt-2                  : #EF3A6D;
  --c-core-alt                    : #bc073a;
  --c-core-alt-4                  : #A30021;
  --c-core-alt-5                  : #890007;
  --c-alt-1                  : #FF5487;
  --c-alt-2                  : #EF3A6D;
  --c-alt                    : #bc073a;
  --c-alt-4                  : #A30021;
  --c-alt-5                  : #890007;

  --c-text: #333333;

  --c-bg: white;
  --c-core-bg: white;

  --c-gray-1: #f6f7f9;

  --border-radius-sharp     : calc(4 * var(--base-margin));
  --border-radius-s         : var(--border-radius-sharp);
}

.max-content-width {
  width: max-content;
}

.cancel-min-width {
  input {
    min-width: unset !important;
  }
}

.no-wrap {
  white-space: nowrap;
}

.line-through {
  text-decoration: line-through;
}

.width-percent-50 {
  width: 50%;
}

.square-xl {
  width: var(--margin-xl);
  height: var(--margin-xl);
}

.flex-1 {
  flex: 1;
}

.drawer .drawer-content {
  padding: var(--margin-s) var(--margin-m) var(--margin-m) var(--margin-m);
  height: 90%
}

.drawer .drawer-header {
  height: 5%
}

// 6th: other custom CSS
*,
*::before,
*::after {
  box-sizing: border-box;
}

// 6th: custom css
html,
body {
  background: var(--c-bg);

  &[lang="he"] * {
    font-family: "Heebo",  -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  }
}
